@import '../../../styles/customMediaQueries.css';

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodLabel {
  composes: label from global;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
