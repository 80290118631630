@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.deliveryField,
.priceField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodLabel {
  composes: label from global;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
}
